//本地引入
import Layout from "@/layout/index.vue";

//公共库
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

//使用路由
Vue.use(VueRouter);

//-------------------------- 路由配置 --------------------------
/**
 * 动态路由
 */
export const asyncRoutes: RouteConfig[] = [
  //主页
  {
    //基本信息
    path: "/",
    component: Layout,
    redirect: "/dashboard",

    //子页面
    children: [
      {
        //基本信息
        path: "dashboard",
        name: "Dashboard",
        component: () => import("@/views/base/dashboard/index.vue"),

        //参数
        meta: {
          //标题
          title: "主页",

          //基本信息
          affix: true,
          noCache: false,
          ignorable: true,
          icon: "dashboard",
        },
      },
    ],
  },

  //管理员
  {
    //基本信息
    component: Layout,
    path: "/trimo/admin",
    redirect: "/trimo/admin/index",

    //参数
    meta: {
      //基本信息
      icon: "user",
      roles: ["admin"],
      alwaysShow: true,
      title: "管理员",
    },

    //子页面
    children: [
      //管理员账号
      {
        //基本信息
        name: "User",
        path: "index",
        component: () => import("@/views/trimo/admin/index.vue"),

        //参数
        meta: {
          //基本信息
          noCache: true,
          title: "管理员账号",
        },
      },

      //角色权限管理
      {
        //基本信息
        name: "Permission",
        path: "permission",
        component: () => import("@/views/trimo/admin/permission.vue"),

        //参数
        meta: {
          //基本信息
          noCache: true,
          title: "角色权限管理",
        },
      },
    ],
  },

  //会员中心
  {
    //基本信息
    component: Layout,
    path: "/trimo/player",
    redirect: "/trimo/player/index",

    //参数
    meta: {
      //基本信息
      icon: "people",
      title: "会员中心",
      roles: ["admin"],
      alwaysShow: true,
    },

    //子页面
    children: [
      //玩家列表
      {
        //基本信息
        path: "index",
        name: "Player",
        component: () => import("@/views/trimo/player/index.vue"),

        //参数
        meta: {
          noCache: false,
          title: "玩家列表",
        },
      },

      //公会列表
      {
        //基本信息
        path: "union_list",
        name: "union_list",
        component: () => import("@/views/trimo/player/union_list.vue"),

        //参数
        meta: {
          noCache: false,
          title: "公会列表",
        },
      },

      //博主审核列表
      {
        //基本信息
        path: "blogger_list",
        name: "blogger_list",
        component: () => import("@/views/trimo/player/blogger_list.vue"),

        //参数
        meta: {
          noCache: false,
          title: "博主审核列表",
        },
      },

      //用户认证审核列表
      {
        //基本信息
        path: "certified_list",
        name: "certified_list",
        component: () => import("@/views/trimo/player/certified_list.vue"),

        //参数
        meta: {
          noCache: false,
          title: "用户认证审核列表",
        },
      },
    ],
  },

  //道具管理
  {
    //基本信息
    component: Layout,
    path: "/trimo/item",
    redirect: "/trimo/item/gift_set",

    //参数
    meta: {
      icon: "icon",
      roles: ["admin"],
      alwaysShow: true,
      title: "道具管理",
    },

    //子页面
    children: [
      //礼包设置
      {
        //基本信息
        path: "gift_set",
        name: "gift_set",
        component: () => import("@/views/trimo/item/gift_set.vue"),

        //参数
        meta: {
          noCache: false,
          title: "礼包设置",
        },
      },

      //礼包发放
      {
        //基本信息
        path: "gift_send",
        name: "gift_send",
        component: () => import("@/views/trimo/item/gift_send.vue"),

        //参数
        meta: {
          noCache: false,
          title: "礼包发放",
        },
      },

      //道具属性
      {
        //基本信息
        path: "item_prop",
        name: "item_prop",
        component: () => import("@/views/trimo/item/item_prop.vue"),

        //参数
        meta: {
          noCache: false,
          title: "道具属性",
        },
      },

      //道具资源
      {
        //基本信息
        path: "item_res_conf",
        name: "item_res_conf",
        component: () => import("@/views/trimo/item/item_res_conf.vue"),

        //参数
        meta: {
          noCache: false,
          title: "道具资源",
        },
      },

      //道具赠送
      {
        //基本信息
        path: "item_send",
        name: "item_send",
        component: () => import("@/views/trimo/item/item_send.vue"),

        //参数
        meta: {
          noCache: false,
          title: "道具赠送",
        },
      },

      //背包查询
      {
        //基本信息
        path: "back_pack",
        name: "back_pack",
        component: () => import("@/views/trimo/item/back_pack.vue"),

        //参数
        meta: {
          noCache: false,
          title: "背包查询",
        },
      },

      //道具列表
      {
        path: "item_conf",
        name: "item_conf",
        component: () => import("@/views/trimo/item/item_conf.vue"),

        //参数
        meta: {
          noCache: false,
          title: "道具列表",
        },
      },

      //领取记录
      {
        path: "gift_record",
        name: "gift_record",
        component: () => import("@/views/trimo/item/gift_record.vue"),

        //参数
        meta: {
          noCache: false,
          title: "领取记录",
        },
      },

      //道具存量统计
      {
        path: "gift_statistics",
        name: "gift_statistics",
        component: () => import("@/views/trimo/item/gift_statistics.vue"),

        //参数
        meta: {
          noCache: false,
          title: "道具存量统计",
        },
      },
    ],
  },

  //商城管理
  {
    //基本信息
    component: Layout,
    path: "/trimo/mall",
    redirect: "/trimo/mall/mall_diamond",

    //参数
    meta: {
      //基本信息
      icon: "shopping",
      roles: ["admin"],
      alwaysShow: true,
      title: "商城管理",
    },

    //子页面
    children: [
      //钻石商城
      {
        //基本信息
        path: "mall_diamond",
        name: "mall_diamond",
        component: () => import("@/views/trimo/mall/mall_diamond.vue"),

        //参数
        meta: {
          noCache: false,
          title: "钻石商城",
        },
      },

      //互动币商城
      {
        //基本信息
        path: "mall_coin",
        name: "mall_coin",
        component: () => import("@/views/trimo/mall/mall_coin.vue"),

        //参数
        meta: {
          noCache: false,
          title: "互动币商城",
        },
      },
    ],
  },

  //任务系统
  {
    //基本信息
    component: Layout,
    path: "/trimo/task",
    redirect: "/trimo/task/task_daily",

    //参数
    meta: {
      //基本信息
      icon: "edit",
      roles: ["admin"],
      alwaysShow: true,
      title: "任务系统",
    },

    //子页面
    children: [
      //签到任务
      {
        //基本信息
        path: "task_daily",
        name: "task_daily",
        component: () => import("@/views/trimo/task/task_daily.vue"),

        //参数
        meta: {
          noCache: false,
          title: "签到任务",
        },
      },

      //新手任务
      {
        //基本信息
        path: "task_newbie",
        name: "task_newbie",
        component: () => import("@/views/trimo/task/task_newbie.vue"),

        //参数
        meta: {
          noCache: false,
          title: "新手任务",
        },
      },

      //内测任务
      {
        //基本信息
        path: "task_beta",
        name: "task_beta",
        component: () => import("@/views/trimo/task/task_beta.vue"),

        //参数
        meta: {
          noCache: false,
          title: "内测任务",
        },
      },
    ],
  },

  //流水查询
  {
    //基本信息
    component: Layout,
    path: "/trimo/pipelining",
    redirect: "/trimo/pipelining/props_flow",

    //参数
    meta: {
      //基本信息
      icon: "search",
      roles: ["admin"],
      alwaysShow: true,
      title: "流水查询",
    },

    //子页面
    children: [
      //道具流水
      {
        //基本信息
        path: "props_flow",
        name: "props_flow",
        component: () => import("@/views/trimo/pipelining/props_flow.vue"),

        //参数
        meta: {
          noCache: true,
          title: "道具流水",
        },
      },

      //私信记录
      {
        //基本信息
        path: "private_letter",
        name: "private_letter",
        component: () => import("@/views/trimo/pipelining/private_letter.vue"),

        //参数
        meta: {
          noCache: true,
          title: "私信记录",
        },
      },

      //博主选配记录
      {
        //基本信息
        path: "blogger_match",
        name: "blogger_match",
        component: () => import("@/views/trimo/pipelining/blogger_match.vue"),

        //参数
        meta: {
          noCache: true,
          title: "博主选配记录",
        },
      },

      //视频语音记录
      {
        //基本信息
        path: "voice_video",
        name: "voice_video",
        component: () => import("@/views/trimo/pipelining/voice_video.vue"),

        //参数
        meta: {
          noCache: true,
          title: "视频语音记录",
        },
      },
    ],
  },

  //工作室
  {
    //基本信息
    component: Layout,
    path: "/trimo/studio",
    redirect: "/trimo/studio/blogger_credits",

    //参数
    meta: {
      //基本信息
      icon: "tree",
      title: "工作室",
      roles: ["admin"],
      alwaysShow: true,
    },

    //子页面
    children: [
      //博主积分
      {
        //基本信息
        path: "blogger_credits",
        name: "blogger_credits",
        component: () => import("@/views/trimo/studio/blogger_credits.vue"),

        //参数
        meta: {
          noCache: true,
          title: "博主积分",
        },
      },

      //成员审核
      {
        //基本信息
        path: "member_review",
        name: "member_review",
        component: () => import("@/views/trimo/studio/member_review.vue"),

        //参数
        meta: {
          noCache: true,
          title: "成员审核",
        },
      },

      //信誉点查询
      {
        //基本信息
        path: "credibility_points",
        name: "credibility_points",
        component: () => import("@/views/trimo/studio/credibility_points.vue"),

        //参数
        meta: {
          noCache: true,
          title: "信誉点查询",
        },
      },

      //工作室管理
      {
        //基本信息
        path: "studio_manage",
        name: "studio_manage",
        component: () => import("@/views/trimo/studio/studio_manage.vue"),

        //参数
        meta: {
          noCache: true,
          title: "工作室管理",
        },
      },
    ],
  },

  //系统公告
  {
    //基本信息
    component: Layout,
    path: "/trimo/bulletin",
    redirect: "/trimo/bulletin/announcement",

    //参数
    meta: {
      //基本信息
      icon: "guide",
      roles: ["admin"],
      alwaysShow: true,
      title: "系统公告",
    },

    //子页面
    children: [
      //官方消息
      {
        //基本信息
        path: "announcement",
        name: "announcement",
        component: () => import("@/views/trimo/bulletin/announcement.vue"),

        //参数
        meta: {
          noCache: true,
          title: "官方消息",
        },
      },

      //系统消息
      {
        //基本信息
        path: "systemNotify",
        name: "systemNotify",
        component: () => import("@/views/trimo/bulletin/system_notify.vue"),

        //参数
        meta: {
          noCache: true,
          title: "系统消息",
        },
      },
    ],
  },

  //大数据
  {
    //基本信息
    component: Layout,
    path: "/trimo/bigdata",
    redirect: "/trimo/bigdata/online_list",

    //参数
    meta: {
      //基本信息
      icon: "example",
      title: "大数据",
      roles: ["admin"],
      alwaysShow: true,
    },

    //子页面
    children: [
      //在线列表
      {
        //基本信息
        path: "online_list",
        name: "online_list",
        component: () => import("@/views/trimo/bigdata/online_list.vue"),

        //参数
        meta: {
          noCache: true,
          title: "在线列表",
        },
      },

      //在线人数
      {
        //基本信息
        path: "online_users",
        name: "online_users",
        component: () => import("@/views/trimo/bigdata/online_users.vue"),

        //参数
        meta: {
          noCache: true,
          title: "在线人数",
        },
      },

      //在线时长统计
      {
        //基本信息
        path: "online_time",
        name: "online_time",
        component: () => import("@/views/trimo/bigdata/online_time.vue"),

        //参数
        meta: {
          noCache: true,
          title: "在线时长统计",
        },
      },

      //【新】在线时长统计
      {
        //基本信息
        path: "new_online_time",
        name: "new_online_time",
        component: () => import("@/views/trimo/bigdata/new_online_time.vue"),

        //参数
        meta: {
          noCache: true,
          title: "【新】在线时长统计",
        },
      },

      //接通统计
      {
        //基本信息
        path: "connect_statistics",
        name: "connect_statistics",
        component: () => import("@/views/trimo/bigdata/connect_statistics.vue"),

        //参数
        meta: {
          noCache: false,
          title: "接通统计",
        },
      },

      //留存统计
      {
        //基本信息
        path: "retention",
        name: "retention",
        component: () => import("@/views/trimo/bigdata/retention.vue"),

        //参数
        meta: {
          noCache: true,
          title: "留存统计",
        },
      },

      //钻石消耗统计
      {
        //基本信息
        path: "diamond_statistics",
        name: "diamond_statistics",
        component: () => import("@/views/trimo/bigdata/diamond_statistics.vue"),

        //参数
        meta: {
          noCache: true,
          title: "钻石消耗统计",
        },
      },

      //支付报表
      {
        //基本信息
        path: "payment_statement",
        name: "payment_statement",
        component: () => import("@/views/trimo/bigdata/payment_statement.vue"),

        //参数
        meta: {
          noCache: true,
          title: "支付报表",
        },
      },

      //活跃用户
      {
        //基本信息
        path: "active_user",
        name: "active_user",
        component: () => import("@/views/trimo/bigdata/active_user.vue"),

        //参数
        meta: {
          noCache: true,
          title: "活跃用户",
        },
      },

      //广场查询
      {
        //基本信息
        path: "square_query",
        name: "square_query",
        component: () => import("@/views/trimo/bigdata/square_query.vue"),

        //参数
        meta: {
          noCache: true,
          title: "广场查询",
        },
      },

      //新手72h优惠
      {
        //基本信息
        path: "newbie_offer",
        name: "newbie_offer",
        component: () => import("@/views/trimo/bigdata/newbie_offer.vue"),

        //参数
        meta: {
          noCache: true,
          title: "新手72h优惠",
        },
      },

      //用户活跃时长
      {
        //基本信息
        path: "user_active_time",
        name: "user_active_time",
        component: () => import("@/views/trimo/bigdata/user_active_time.vue"),

        //参数
        meta: {
          noCache: true,
          title: "用户活跃时长",
        },
      },

      //App界面停留统计
      {
        //基本信息
        path: "stay_statistics",
        name: "stay_statistics",
        component: () => import("@/views/trimo/bigdata/stay_statistics.vue"),

        //参数
        meta: {
          noCache: false,
          title: "APP界面停留统计",
        },
      },

      //首页博主泡泡记录
      {
        //基本信息
        path: "blogger_bubbles_log",
        name: "blogger_bubbles_log",
        component: () => import("@/views/trimo/bigdata/blogger_bubbles_log.vue"),

        //参数
        meta: {
          noCache: true,
          title: "首页博主泡泡记录",
        },
      },

      //用户月卡记录
      {
        //基本信息
        path: "month_card_record",
        name: "month_card_record",
        component: () => import("@/views/trimo/bigdata/month_card_record.vue"),

        //参数
        meta: {
          noCache: true,
          title: "用户月卡记录",
        },
      },
    ],
  },

  //用户管理
  {
    //基本信息
    component: Layout,
    path: "/trimo/user",
    redirect: "/trimo/user/user_list",

    //参数
    meta: {
      //基本信息
      icon: "peoples",
      roles: ["admin"],
      alwaysShow: true,
      title: "用户管理",
    },

    //子页面
    children: [
      //用户列表
      {
        //基本信息
        name: "user_list",
        path: "user_list",
        component: () => import("@/views/trimo/user/user_list.vue"),

        //参数
        meta: {
          noCache: true,
          title: "用户列表",
        },
      },

      // //多巴用户
      // {
      //   //基本信息
      //   name: "user_risk",
      //   path: "user_risk",
      //   component: () => import("@/views/trimo/user/user_risk.vue"),

      //   //参数
      //   meta: {
      //     noCache: true,
      //     title: "多巴用户",
      //   },
      // },

      //记录查询
      {
        //基本信息
        path: "record_list",
        name: "record_list",
        component: () => import("@/views/trimo/user/record_list.vue"),

        //参数
        meta: {
          noCache: true,
          title: "记录查询",
        },
      },

      //聊天记录查询
      {
        //基本信息
        path: "chat_history",
        name: "chat_history",
        component: () => import("@/views/trimo/user/chat_history.vue"),

        //参数
        meta: {
          noCache: true,
          title: "聊天记录查询",
        },
      },

      //签到日志列表
      {
        //基本信息
        path: "sign_award_log",
        name: "sign_award_log",
        component: () => import("@/views/trimo/user/sign_award_log.vue"),

        //参数
        meta: {
          noCache: true,
          title: "签到日志列表",
        },
      },

      //签到奖励配置列表
      {
        //基本信息
        path: "sign_sward_set",
        name: "sign_sward_set",
        component: () => import("@/views/trimo/user/sign_sward_set.vue"),

        //参数
        meta: {
          noCache: true,
          title: "签到奖励配置列表",
        },
      },

      //用户行为上报记录
      {
        //基本信息
        path: "user_action_peport",
        name: "user_action_peport",
        component: () => import("@/views/trimo/user/user_action_peport.vue"),

        //参数
        meta: {
          noCache: true,
          title: "用户行为上报记录",
        },
      },
    ],
  },

  //博主管理
  {
    //基本信息
    component: Layout,
    path: "/trimo/blogger",
    redirect: "/trimo/blogger/feedback",

    //参数
    meta: {
      //基本信息
      icon: "blogger1",
      roles: ["admin"],
      alwaysShow: true,
      title: "博主管理",
    },

    //子页面
    children: [
      //博主反馈
      {
        //基本信息
        path: "feedback",
        name: "feedback",
        component: () => import("@/views/trimo/blogger/feedback.vue"),

        //参数
        meta: {
          noCache: true,
          title: "博主反馈",
        },
      },

      //博主设置
      {
        //基本信息
        path: "blogger_set",
        name: "blogger_set",
        component: () => import("@/views/trimo/blogger/blogger_set.vue"),

        //参数
        meta: {
          noCache: true,
          title: "博主设置",
        },
      },

      //缘分伴侣
      {
        //基本信息
        path: "companion",
        name: "companion",
        component: () => import("@/views/trimo/blogger/companion.vue"),

        //参数
        meta: {
          noCache: true,
          title: "缘分伴侣",
        },
      },

      //博主引导设置
      {
        //基本信息
        path: "guide_set",
        name: "guide_set",
        component: () => import("@/views/trimo/blogger/guide_set.vue"),

        //参数
        meta: {
          noCache: true,
          title: "博主引导设置",
        },
      },

      //博主引导记录
      {
        //基本信息
        path: "guide_record",
        name: "guide_record",
        component: () => import("@/views/trimo/blogger/guide_record.vue"),

        //参数
        meta: {
          noCache: true,
          title: "博主引导记录",
        },
      },

      //博主推荐记录
      {
        //基本信息
        path: "blogger_recommend_record",
        name: "blogger_recommend_record",
        component: () => import("@/views/trimo/blogger/recommend_record.vue"),

        //参数
        meta: {
          noCache: true,
          title: "博主推荐记录",
        },
      },

      //博主指令轮盘
      {
        //基本信息
        path: "blogger_game_wheel",
        name: "blogger_game_wheel",
        component: () => import("@/views/trimo/blogger/game_wheel.vue"),

        //参数
        meta: {
          noCache: true,
          title: "博主指令轮盘",
        },
      },

      //博主默认消息
      {
        //基本信息
        path: "blogger_default_call",
        name: "blogger_default_call",
        component: () => import("@/views/trimo/blogger/default_call.vue"),

        //参数
        meta: {
          noCache: true,
          title: "博主默认消息",
        },
      },

      //博主价格设置
      {
        //基本信息
        path: "blogger_price_set",
        name: "blogger_price_set",
        component: () => import("@/views/trimo/blogger/price_set.vue"),

        //参数
        meta: {
          noCache: true,
          title: "博主价格设置",
        },
      },

      //博主活跃时长
      {
        //基本信息
        path: "active_duration",
        name: "active_duration",
        component: () => import("@/views/trimo/blogger/active_duration.vue"),

        //参数
        meta: {
          noCache: true,
          title: "博主活跃时长",
        },
      },

      //博主活跃记录
      {
        //基本信息
        path: "active_log",
        name: "active_log",
        component: () => import("@/views/trimo/blogger/active_log.vue"),

        //参数
        meta: {
          noCache: true,
          title: "博主活跃记录",
        },
      },

      //视频开关时长
      {
        //基本信息
        path: "video_switch_duration",
        name: "video_switch_duration",
        component: () => import("@/views/trimo/blogger/video_switch_duration.vue"),

        //参数
        meta: {
          noCache: true,
          title: "视频开关时长",
        },
      },

      //视频开关记录
      {
        //基本信息
        path: "video_switch_log",
        name: "video_switch_log",
        component: () => import("@/views/trimo/blogger/video_switch_log.vue"),

        //参数
        meta: {
          noCache: true,
          title: "视频开关记录",
        },
      },

      //博主充值推送记录
      {
        //基本信息
        path: "blogger_recharge_record",
        name: "blogger_recharge_record",
        component: () => import("@/views/trimo/blogger/recharge_record.vue"),

        //参数
        meta: {
          noCache: true,
          title: "博主充值推送记录",
        },
      },

      //博主回复效率统计
      {
        //基本信息
        path: "statistics_reply_count",
        name: "statistics_reply_count",
        component: () => import("@/views/trimo/blogger/statistics_reply_count.vue"),

        //参数
        meta: {
          noCache: true,
          title: "博主回复效率统计",
        },
      },

      //博主通话效率统计
      {
        //基本信息
        path: "statistics_call_count",
        name: "statistics_call_count",
        component: () => import("@/views/trimo/blogger/statistics_call_count.vue"),

        //参数
        meta: {
          noCache: true,
          title: "博主通话效率统计",
        },
      },

      //博主帖子推荐配置列表
      {
        //基本信息
        path: "recommend_set",
        name: "recommend_set",
        component: () => import("@/views/trimo/blogger/post_recommend_set.vue"),

        //参数
        meta: {
          noCache: true,
          title: "博主帖子推荐配置列表",
        },
      },

      //博主帖子推荐记录列表
      {
        //基本信息
        path: "recommend_record",
        name: "recommend_record",
        component: () => import("@/views/trimo/blogger/post_recommend_record.vue"),

        //参数
        meta: {
          noCache: true,
          title: "博主帖子推荐记录列表",
        },
      },

      //博主帖子推荐用户列表
      {
        //基本信息
        path: "recommend_user_list",
        name: "recommend_user_list",
        component: () => import("@/views/trimo/blogger/post_recommend_user_list.vue"),

        //参数
        meta: {
          noCache: true,
          title: "博主帖子推荐用户列表",
        },
      },

      //多巴博主标签配置列表
      {
        //基本信息
        path: "risk_blogger_label_list",
        name: "risk_blogger_label_list",
        component: () => import("@/views/trimo/blogger/risk_blogger_label_list.vue"),

        //参数
        meta: {
          noCache: true,
          title: "多巴博主标签配置列表",
        },
      },

      //订阅关系列表
      {
        //基本信息
        path: "dy_user",
        name: "dy_user",
        component: () => import("@/views/trimo/blogger/dy_user.vue"),

        //参数
        meta: {
          noCache: true,
          title: "订阅关系列表",
        },
      },

      //订阅记录列表
      {
        //基本信息
        path: "dy_log",
        name: "dy_log",
        component: () => import("@/views/trimo/blogger/dy_log.vue"),

        //参数
        meta: {
          noCache: true,
          title: "订阅记录列表",
        },
      },

      //订阅奖励列表
      {
        //基本信息
        path: "dy_award",
        name: "dy_award",
        component: () => import("@/views/trimo/blogger/dy_award.vue"),

        //参数
        meta: {
          noCache: true,
          title: "订阅奖励列表",
        },
      },

      //代运营管理
      {
        //基本信息
        path: "assist_manager",
        name: "assist_manager",
        component: () => import("@/views/trimo/blogger/assist_manager.vue"),

        //参数
        meta: {
          noCache: true,
          title: "代运营管理",
        },
      },
    ],
  },

  //充提管理
  {
    //基本信息
    component: Layout,
    path: "/trimo/recharge",
    redirect: "/trimo/recharge/record",

    //参数
    meta: {
      //基本信息
      roles: ["admin"],
      alwaysShow: true,
      title: "充提管理",
      icon: "withdrawal",
    },

    //子页面
    children: [
      //线下充值
      {
        //基本信息
        path: "recharge_pay",
        name: "recharge_pay",
        component: () => import("@/views/trimo/recharge/recharge_pay.vue"),

        //参数
        meta: {
          noCache: true,
          title: "线下充值",
        },
      },

      //充值记录
      {
        //基本信息
        path: "recharge_record",
        name: "recharge_record",
        component: () => import("@/views/trimo/recharge/recharge_record.vue"),

        //参数
        meta: {
          noCache: true,
          title: "充值记录",
        },
      },

      //用户充值统计
      {
        //基本信息
        path: "recharge_statistics",
        name: "recharge_statistics",
        component: () => import("@/views/trimo/recharge/recharge_statistics.vue"),

        //参数
        meta: {
          noCache: true,
          title: "用户充值统计",
        },
      },

      //AppsFlyer充值
      {
        //基本信息
        path: "apps_flyer_recharge",
        name: "apps_flyer_recharge",
        component: () => import("@/views/trimo/recharge/apps_flyer_recharge.vue"),

        //参数
        meta: {
          noCache: true,
          title: "AppsFlyer充值",
        },
      },
    ],
  },

  //用户监管
  {
    //基本信息
    component: Layout,
    path: "/trimo/supervise",
    redirect: "/trimo/supervise/square",

    //参数
    meta: {
      //基本信息
      icon: "safety",
      roles: ["admin"],
      alwaysShow: true,
      title: "用户监管",
    },

    //子页面
    children: [
      //广场监管
      {
        //基本信息
        path: "square",
        name: "square",
        component: () => import("@/views/trimo/supervise/square.vue"),

        //参数
        meta: {
          noCache: true,
          title: "广场监管",
        },
      },

      //相册监管
      {
        //基本信息
        path: "album",
        name: "album",
        component: () => import("@/views/trimo/supervise/album.vue"),

        //参数
        meta: {
          noCache: true,
          title: "相册监管",
        },
      },

      //头像监管
      {
        //基本信息
        path: "avatar",
        name: "avatar",
        component: () => import("@/views/trimo/supervise/avatar.vue"),

        //参数
        meta: {
          noCache: true,
          title: "头像监管",
        },
      },

      //在线监管
      {
        //基本信息
        path: "online",
        name: "online",
        component: () => import("@/views/trimo/supervise/online.vue"),

        //参数
        meta: {
          noCache: true,
          title: "在线监管",
        },
      },

      //IM监管
      {
        //基本信息
        path: "im_supervise",
        name: "im_supervise",
        component: () => import("@/views/trimo/supervise/im_supervise.vue"),

        //参数
        meta: {
          noCache: true,
          title: "IM监管",
        },
      },
    ],
  },

  //标签管理
  {
    //基本信息
    component: Layout,
    path: "/trimo/tag",
    redirect: "/trimo/tag/tag_library",

    //参数
    meta: {
      //基本信息
      icon: "tag",
      title: "标签管理",
      roles: ["admin"],
      alwaysShow: true,
    },

    //子页面
    children: [
      //标签管理
      {
        //基本信息
        path: "tags_manager",
        name: "tags_manager",
        component: () => import("@/views/trimo/tag/tags_manager.vue"),

        //参数
        meta: {
          noCache: true,
          title: "标签管理",
        },
      },

      //用户标签管理
      {
        //基本信息
        path: "user_tags_manager",
        name: "user_tags_manager",
        component: () => import("@/views/trimo/tag/user_tags_manager.vue"),

        //参数
        meta: {
          noCache: true,
          title: "用户标签管理",
        },
      },

      //博主标签管理
      {
        //基本信息
        path: "blogger_tags_manager",
        name: "blogger_tags_manager",
        component: () => import("@/views/trimo/tag/blogger_tags_manager.vue"),

        //参数
        meta: {
          noCache: true,
          title: "博主标签管理",
        },
      },

      //风控标签管理
      {
        //基本信息
        path: "tags_library",
        name: "tags_library",
        component: () => import("@/views/trimo/tag/tags_library.vue"),

        //参数
        meta: {
          noCache: true,
          title: "风控标签管理",
        },
      },

      //风控标签组管理
      {
        //基本信息
        path: "tagsgroup",
        name: "tagsgroup",
        component: () => import("@/views/trimo/tag/tags_group.vue"),

        //参数
        meta: {
          noCache: true,
          title: "风控标签组管理",
        },
      },

      //风控用户标签查询
      {
        //基本信息
        path: "tags_search",
        name: "tags_search",
        component: () => import("@/views/trimo/tag/tags_search.vue"),

        //参数
        meta: {
          noCache: true,
          title: "风控用户标签查询",
        },
      },
    ],
  },

  //行为查询
  {
    //基本信息
    component: Layout,
    path: "/trimo/behavior",
    redirect: "/trimo/behavior/follow",

    //参数
    meta: {
      //基本信息
      roles: ["admin"],
      alwaysShow: true,
      title: "行为查询",
      icon: "user-search",
    },

    //子页面
    children: [
      //关注查询
      {
        //基本信息
        path: "follow",
        name: "follow",
        component: () => import("@/views/trimo/behavior/follow.vue"),

        //参数
        meta: {
          noCache: true,
          title: "关注查询",
        },
      },

      //举报查询
      {
        //基本信息
        path: "report",
        name: "report",
        component: () => import("@/views/trimo/behavior/report.vue"),

        //参数
        meta: {
          noCache: true,
          title: "举报查询",
        },
      },

      //黑名单查询
      {
        //基本信息
        path: "black_list",
        name: "black_list",
        component: () => import("@/views/trimo/behavior/black_list.vue"),

        //参数
        meta: {
          noCache: true,
          title: "黑名单查询",
        },
      },

      //熟悉度亲密度
      {
        //基本信息
        path: "familiarity_intimacy",
        name: "familiarity_intimacy",
        component: () => import("@/views/trimo/behavior/familiarity.vue"),

        //参数
        meta: {
          noCache: true,
          title: "熟悉度亲密度",
        },
      },

      //用户操作查询
      {
        //基本信息
        path: "user_action",
        name: "user_action",
        component: () => import("@/views/trimo/behavior/user_action.vue"),

        //参数
        meta: {
          noCache: true,
          title: "用户操作查询",
        },
      },
    ],
  },

  //活动设置
  {
    //基本信息
    component: Layout,
    path: "/trimo/activity",
    redirect: "/trimo/activity/hong_bao_yu",

    //参数
    meta: {
      //基本信息
      roles: ["admin"],
      alwaysShow: true,
      title: "活动设置",
      icon: "activity",
    },

    //子页面
    children: [
      //红包雨
      {
        //基本信息
        path: "hong_bao_yu",
        name: "hong_bao_yu",
        component: () => import("@/views/trimo/activity/hong_bao_yu.vue"),

        //参数
        meta: {
          noCache: true,
          title: "红包雨",
        },
      },

      //礼物墙
      {
        //基本信息
        path: "gift_wall",
        name: "gift_wall",
        component: () => import("@/views/trimo/activity/gift_wall.vue"),

        //参数
        meta: {
          noCache: true,
          title: "礼物墙",
        },
      },

      //女神大赛
      {
        //基本信息
        path: "goddess_contest",
        name: "goddess_contest",
        component: () => import("@/views/trimo/activity/goddess_contest.vue"),

        //参数
        meta: {
          noCache: true,
          title: "女神大赛",
        },
      },

      //充值抽奖活动
      {
        //基本信息
        path: "recharge_lottery",
        name: "Recharge recharge_lottery",
        component: () => import("@/views/trimo/activity/recharge_lottery.vue"),

        //参数
        meta: {
          noCache: true,
          title: "充值抽奖活动",
        },
      },

      //KOL活动管理
      {
        //基本信息
        path: "kol_manager",
        name: "kol_manager",
        component: () => import("@/views/trimo/activity/kol_manager.vue"),

        //参数
        meta: {
          noCache: true,
          title: "KOL活动管理",
        },
      },

      //H5活动管理
      {
        //基本信息
        path: "h5_manager",
        name: "h5_manager",
        component: () => import("@/views/trimo/activity/h5_manager.vue"),

        //参数
        meta: {
          noCache: true,
          title: "H5活动管理",
        },
      },

      //PK赛管理
      {
        //基本信息
        path: "pk_race",
        name: "pk_race",
        component: () => import("@/views/trimo/activity/pk_race.vue"),

        //参数
        meta: {
          noCache: true,
          title: "PK赛管理",
        },
      },
    ],
  },

  //推广查询
  {
    //基本信息
    component: Layout,
    path: "/trimo/promotion",
    redirect: "/trimo/promotion/promotion",

    //参数
    meta: {
      //基本信息
      title: "推广查询",
      roles: ["admin"],
      alwaysShow: true,
      icon: "promotion",
    },

    //子页面
    children: [
      //推广佣金查询
      {
        //基本信息
        path: "commission_inquiry",
        name: "commission_inquiry",
        component: () => import("@/views/trimo/promotion/promotion.vue"),

        //参数
        meta: {
          noCache: true,
          title: "推广佣金查询",
        },
      },

      //推广佣金设置
      {
        //基本信息
        path: "commission_set",
        name: "commission_set",
        component: () => import("@/views/trimo/promotion/promotion_set.vue"),

        //参数
        meta: {
          noCache: true,
          title: "推广佣金设置",
        },
      },

      //用户推广查询
      {
        //基本信息
        path: "user_promotion",
        name: "user_promotion",
        component: () => import("@/views/trimo/promotion/user_promotion.vue"),

        //参数
        meta: {
          noCache: true,
          title: "用户推广查询",
        },
      },

      //用户推广设置
      {
        //基本信息
        path: "user_promotion_set",
        name: "user_promotion_set",
        component: () => import("@/views/trimo/promotion/user_promotion_set.vue"),

        //参数
        meta: {
          noCache: true,
          title: "用户推广设置",
        },
      },

      //用户推广奖励记录
      {
        //基本信息
        path: "user_promotion_record",
        name: "user_promotion_record",
        component: () => import("@/views/trimo/promotion/user_promotion_record.vue"),

        //参数
        meta: {
          noCache: true,
          title: "用户推广奖励记录",
        },
      },

      //博主推广查询
      {
        //基本信息
        path: "blogger_promotion",
        name: "blogger_promotion",
        component: () => import("@/views/trimo/promotion/blogger_promotion.vue"),

        //参数
        meta: {
          noCache: true,
          title: "博主推广查询",
        },
      },

      //博主推广设置
      {
        //基本信息
        path: "blogger_promotion_set",
        name: "blogger_promotion_set",
        component: () => import("@/views/trimo/promotion/blogger_promotion_set.vue"),

        //参数
        meta: {
          noCache: true,
          title: "博主推广设置",
        },
      },

      //博主推广奖励记录
      {
        //基本信息
        path: "blogger_promotion_record",
        name: "blogger_promotion_record",
        component: () => import("@/views/trimo/promotion/blogger_promotion_record.vue"),

        //参数
        meta: {
          noCache: true,
          title: "博主推广奖励记录",
        },
      },
    ],
  },

  //渠道查询
  {
    //基本信息
    component: Layout,
    path: "/trimo/channel",
    redirect: "/trimo/channel/channel_list",

    //参数
    meta: {
      //基本信息
      icon: "channel",
      title: "渠道查询",
      roles: ["admin"],
      alwaysShow: true,
    },

    //子页面
    children: [
      //渠道列表
      {
        //基本信息
        path: "channel_list",
        name: "channel_list",
        component: () => import("@/views/trimo/channel/channel_list.vue"),

        //参数
        meta: {
          noCache: true,
          title: "渠道列表",
        },
      },
    ],
  },

  //客服管理
  {
    //基本信息
    component: Layout,
    path: "/trimo/service",
    redirect: "/trimo/customer/customer_list",

    //参数
    meta: {
      //基本信息
      icon: "customer",
      title: "客服管理",
      roles: ["admin"],
      alwaysShow: true,
    },

    //子页面
    children: [
      //客服列表
      {
        //基本信息
        path: "customer_list",
        name: "customer_list",
        component: () => import("@/views/trimo/customer/customer_list.vue"),

        //参数
        meta: {
          noCache: true,
          title: "客服列表",
        },
      },

      //客服标签
      {
        //基本信息
        path: "customer_tag",
        name: "customer_tag",
        component: () => import("@/views/trimo/customer/customer_tag.vue"),

        //参数
        meta: {
          noCache: true,
          title: "客服标签",
        },
      },

      //客服标签
      {
        //基本信息
        path: "customer_tag_user",
        name: "customer_tag_user",
        component: () => import("@/views/trimo/customer/customer_tag_user.vue"),

        //参数
        meta: {
          noCache: true,
          title: "客服标签用户",
        },
      },
    ],
  },

  //系统设置
  {
    //基本信息
    component: Layout,
    path: "/trimo/system",
    redirect: "/trimo/system/ranking",

    //参数
    meta: {
      //基本信息
      icon: "system",
      title: "系统设置",
      roles: ["admin"],
      alwaysShow: true,
    },

    //子页面
    children: [
      //排行榜
      {
        //基本信息
        path: "ranking",
        name: "ranking",
        component: () => import("@/views/trimo/system/ranking.vue"),

        //参数
        meta: {
          noCache: true,
          title: "排行榜",
        },
      },

      //默认消息
      {
        //基本信息
        path: "default_msg",
        name: "default_msg",
        component: () => import("@/views/trimo/system/default_msg.vue"),

        //参数
        meta: {
          noCache: true,
          title: "默认消息",
        },
      },

      //融云推送
      {
        //基本信息
        path: "ry_push",
        name: "default_mry_pushsg",
        component: () => import("@/views/trimo/system/ry_push.vue"),

        //参数
        meta: {
          noCache: true,
          title: "融云推送",
        },
      },
    ],
  },

  //守护勋章管理
  {
    //基本信息
    component: Layout,
    path: "/trimo/medal",
    redirect: "/trimo/medal/systematics_list",

    //参数
    meta: {
      //基本信息
      icon: "medal",
      title: "守护勋章管理",
      roles: ["admin"],
      alwaysShow: true,
    },

    //子页面
    children: [
      //分类列表
      {
        //基本信息
        path: "systematics_list",
        name: "systematics_list",
        component: () => import("@/views/trimo/medal/systematics_list.vue"),

        //参数
        meta: {
          noCache: true,
          title: "分类列表",
        },
      },

      //勋章列表
      {
        //基本信息
        path: "medal_list",
        name: "medal_list",
        component: () => import("@/views/trimo/medal/medal_list.vue"),

        //参数
        meta: {
          noCache: true,
          title: "勋章列表",
        },
      },

      //勋章发放
      {
        //基本信息
        path: "medal_send",
        name: "medal_send",
        component: () => import("@/views/trimo/medal/medal_send.vue"),

        //参数
        meta: {
          noCache: true,
          title: "勋章发放",
        },
      },
    ],
  },

  //系统管理
  {
    //基本信息
    component: Layout,
    path: "/trimo/setting",
    redirect: "/trimo/setting/operation_log",

    //参数
    meta: {
      //基本信息
      icon: "nested",
      title: "系统管理",
      roles: ["admin"],
      alwaysShow: true,
    },

    //子页面
    children: [
      //操作日志查询
      {
        //基本信息
        path: "operation_log",
        name: "operation_log",
        component: () => import("@/views/trimo/setting/operation_log.vue"),

        //参数
        meta: {
          noCache: true,
          title: "操作日志查询",
        },
      },
    ],
  },

  //404页面
  {
    path: "*",
    redirect: "/404",
    meta: { hidden: true },
  },
];

/**
 * 基础路由(没有权限要求)
 */
export const constantRoutes: RouteConfig[] = [
  //重定向
  {
    path: "/redirect",
    component: Layout,
    meta: { hidden: true },
    children: [
      {
        path: "/redirect/:path(.*)",
        component: () => import("@/views/base/redirect/index.vue"),
      },
    ],
  },

  //登录界面
  {
    path: "/login",
    meta: { hidden: true },
    component: () => import("@/views/base/login/index.vue"),
  },

  //自动重定向
  {
    path: "/auth-redirect",
    meta: { hidden: true },
    component: () => import("@/views/base/login/auth-redirect.vue"),
  },

  //刷新界面
  {
    path: "/refresh",
    meta: { hidden: true },
    component: () => import("@/views/base/error-page/refresh.vue"),
  },

  //404界面
  {
    path: "/404",
    meta: { hidden: true },
    component: () => import("@/views/base/error-page/404.vue"),
  },

  //401界面
  {
    path: "/401",
    meta: { hidden: true },
    component: () => import("@/views/base/error-page/401.vue"),
  },

  //个人中心
  {
    path: "/profile",
    component: Layout,
    redirect: "/profile/index",
    meta: { hidden: true },
    children: [
      {
        path: "index",
        name: "Profile",
        component: () => import("@/views/base/profile/index.vue"),
        meta: { title: "个人中心", icon: "user", noCache: true },
      },
    ],
  },
];

//-------------------------- 调用函数 --------------------------
//创建路由
const createRouter = () =>
  new VueRouter({
    //创建路由
    scrollBehavior: (to, from, savedPosition) => {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { x: 0, y: 0 };
      }
    },

    //基础路由
    routes: constantRoutes,

    //基础路径
    base: process.env.BASE_URL,
  });

//初始化路由
const router = createRouter();

//重置路由
export function resetRouter() {
  //初始化路由
  const newRouter = createRouter();

  //重置路由
  (router as any).matcher = (newRouter as any).matcher;
}

//更新动态路由
export const updateAsyncRoutes = () => {};

//导出
export default router;
